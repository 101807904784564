<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>CSV出力（仕入）</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <!-- 日付設定 -->
              <v-radio-group
                v-model="dateConfiguration"
                label="日付設定"
                mandatory
                dense
                hide-details="false" >
                <v-radio label="15日締" value="0"></v-radio>
                <v-radio label="月末締" value="1"></v-radio>
              </v-radio-group>
              <!-- 設定 -->
              <v-btn
                color="secondary"
                v-on:click="onClickSetting"
                rounded
                class="my-2"
                ><v-icon left> mdi-cog </v-icon>設定
              </v-btn>
            </v-form>
          </v-card-text>
          <v-card-text>
            <v-data-table
              dense
              :headers="headers"
              :items="items"
              :item-class="checkSupplierPrice"
              class="elevation-5 mt-5 scroll-lock-search"
              cellpadding=0px
              disable-sort
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>CSV明細</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-toolbar-title>
                    {{ items.length }}件
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-card-actions class="justify-center">
                    <!-- CSV出力 -->
                    <v-btn
                      color="secondary"
                      v-on:click="onClickOutPutCsv"
                      rounded
                      class="my-2"
                      ><v-icon left> mdi-file-delimited </v-icon>CSV出力
                    </v-btn>
                  </v-card-actions>
                </v-toolbar>
              </template>
              <!-- 仕入単価 -->
              <template v-slot:[`item.supplierPrice`]="{ item }">
                <span>{{ new Number(item.supplierPrice).toLocaleString() }}</span>
              </template>
              <!-- 仕入伝票日付 -->
              <template v-slot:[`item.purchaseSlipDate`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.purchaseSlipDate"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>
                    <div class="font-weight-bold" v-if="item.purchaseSlipDateFlg==true"
                    >{{item.purchaseSlipDate}}
                    </div>
                    <div v-else>{{item.purchaseSlipDate}}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">仕入伝票日付</div>
                    <v-menu
                      v-model="item.purchaseSlipDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      left
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.purchaseSlipDate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:append="item.purchaseSlipDateMenu = true"
                          :hint="`OKで同一仕入伝票番号のレコードに反映されます`"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.purchaseSlipDate"
                        @input="item.purchaseSlipDateMenu = false"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 実日付 -->
              <template v-slot:[`item.realDate`]="{ item }">
                <v-edit-dialog
                  :return-value.sync="item.realDate"
                  large
                  cancel-text="キャンセル"
                  save-text="OK"
                  @save="save(item)"
                  @cancel="cancel"
                >
                  <v-chip link>
                    <div class="font-weight-bold" v-if="item.realDateFlg==true"
                      >{{item.realDate}}
                      </div>
                      <div v-else>{{item.realDate}}</div></v-chip>
                  <template v-slot:input>
                    <div class="mt-4 text-h6">実日付</div>
                    <v-menu
                      v-model="item.realDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      left
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="item.realDate"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          clearable
                          @click:append="item.realDateMenu = true"
                          :hint="`OKで同一仕入伝票番号のレコードに反映されます`"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.realDate"
                        @input="item.realDateMenu = false"
                        :day-format="(date) => new Date(date).getDate()"
                      ></v-date-picker>
                    </v-menu>
                  </template>
                </v-edit-dialog>
              </template>
              <!-- 本伝 -->
              <template v-slot:[`item.slipCheck`]="{ item }">
                <v-simple-checkbox
                  v-model="item.slipCheck"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- 返品 -->
              <template v-slot:[`item.returnCheck`]="{ item }">
                <v-simple-checkbox
                  v-model="item.returnCheck"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- 値引 -->
              <template v-slot:[`item.discountCheck`]="{ item }">
                <v-simple-checkbox
                  v-model="item.discountCheck"
                  color="secondary"
                  :ripple="false"
                  disabled
                ></v-simple-checkbox>
              </template>
              <!-- 操作 -->
              <template v-slot:[`item.actions`]="{ item }">
                <v-container>
                  <v-row>
                    <v-tooltip bottom
                      ><template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="ml-1"
                          fab
                          dark
                          x-small
                          color="secondary"
                          v-bind="attrs"
                          v-on="on"
                          @click="onClickDeleteItem(item)"
                          ><v-icon>mdi-delete</v-icon>
                        </v-btn></template
                      >
                      <span>削除</span>
                    </v-tooltip>
                  </v-row>
                </v-container>
                <v-spacer></v-spacer>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { API, graphqlOperation } from 'aws-amplify';
import {
  listW_HACCHU_SHIIRE_CSV_DATA,
} from "@/graphql/queries";
import {
  executeHanbaikanriSql,
  executeTransactSql,
  executeCsvFileCreation,
} from "@/graphql/mutations";
import { CONFIRM_MESSAGE_CSV_REMOVE_DATA, CONFIRM_MESSAGE_CSV_INSERT_DATA, ALERT_MESSAGE_COMMON_ERROR } from "@/assets/js/dialog_messages";
import { addLog } from '@/assets/js/logger';
import MSG from '@/assets/js/messages';
import TYPE from '@/assets/js/type';
export default {
  name: "CsvPrint",

  data: () => ({
    // Datatablesのヘッダー
    headers: [
      { text: "操作", value: "actions", width: "50px" },
      { text: "CSS仕入先コード", value: "cssSupplierCode", width: "80px" },
      { text: "CSS仕入先名", value: "cssSupplierName", width: "200px" },
      { text: "仕入伝票日付", value: "purchaseSlipDate", width: "85px" },
      { text: "実日付", value: "realDate", width: "85px" },
      { text: "仕入先伝票番号", value: "purchaseSlipNo", width: "100px" },
      { text: "商品コード", value: "productCode", width: "80px" },
      { text: "型番", value: "productName", width: "200px" },
      { text: "数量", value: "amount", width: "50px" },
      { text: "仕入単価", value: "supplierPrice", width: "50px" },
      { text: "注文番号", value: "orderNo", width: "40px" },
      { text: "明細No.", value: "orderBranchNo", width: "50px" },
      { text: "本伝", value: "slipCheck", width: "50px" },
      { text: "返品", value: "returnCheck", width: "50px" },
      { text: "値引", value: "discountCheck", width: "50px" },
    ],
    // 日付設定フラグ
    dateConfiguration: "",
    // CSV明細リスト
    items: [],
    // 今日日付
    todayDate: "",
    // データベース照会結果
    sqlSearchListResult: [],
    // 仕入情報リスト
    purchasingInfoList: [],
    // データテーブル内ダイアログでのカレンダー入力
    purchaseSlipDateMenu: false,
    realDateMenu: false,
    // CSV出力しないレコードフラグ
    itemDeleteFlg: false,
  }),
  /**
   * created ライフサイクルフック
   */
  created: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0006"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ログイン中のユーザー情報を取得
    if (!(await this.getLoginUserInfo())) {
      // エラーダイアログを表示
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }
    // 初期化処理の実行
    if (!(await this.initialize())) {
      // エラーダイアログを表示
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }

    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * mounted ライフサイクルフック
   */
  mounted: async function() {
    // メソッド名を定義
    const method_name="function"
    // オペレーションタイプを定義
    const operation_type="0007"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディングを解除
    this.$store.commit("setLoading", false);
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  /**
   * beforeRouteLeave ライフサイクルフック
   */
  async beforeRouteLeave(to, from, next) {
    // メソッド名を定義
    const method_name="beforeRouteLeave"
    // オペレーションタイプを定義
    const operation_type="0008"

    // ログ出力(method-start)
    addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
    // ローディング画面の開始
    this.$store.commit("setLoading", true);
    
    // 仕入CSV出力データのテーブルデータを消去する
    let sqlList = [
      "DELETE FROM W_HACCHU_SHIIRE_CSV_DATA" +
        " WHERE BUMON_CD = " +
        this.loginUserInfo.BUMON_CD +
        " AND TANTOSHA_CD = " +
        this.loginUserInfo.TANTOSHA_CD + ";",
    ];
    if (!(await this.referenceInternalDb(sqlList))) {
      // エラーダイアログを表示
      alert(ALERT_MESSAGE_COMMON_ERROR);
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return;
    }
    // ローディングを解除
    this.$store.commit("setLoading", false);
    next();
    // ログ出力(method-end)
    addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
  },
  methods: {
    /**
     * ログインユーザー情報の取得処理
     * ・ログインユーザーコードの取得
     * ・ログインユーザーの所属部門の取得
     */
    async getLoginUserInfo() {
      // メソッド名を定義
      const method_name="getLoginUserInfo"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログイン中のユーザー情報を取得
      this.loginUserInfo = this.$store.getters.user;

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return (this.loginUserInfo !== null);
    },
    /**
     * 初期化処理
     * ・コンボボックスのデータ取得
     * ・テキストボックスのデータ取得
     */
    async initialize() {
      // メソッド名を定義
      const method_name="initialize"
      // オペレーションタイプを定義
      const operation_type="0001"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);
      // 検索条件
      let condition = {
        where_options:
        "AND BUMON_CD = " +
          this.loginUserInfo.BUMON_CD +
        " AND TANTOSHA_CD = " +
          this.loginUserInfo.TANTOSHA_CD +
        " ORDER BY CSS_SHIIRESAKI_CD ASC," +
        " DENPYO_KBN ASC," +
        " CHAKUBI_DATE ASC," +
        " SHIIRESAKI_DENPYO_NO ASC," +
        " MEISAI_NO ASC",
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // データ取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(listW_HACCHU_SHIIRE_CSV_DATA, condition)
        );
        // 結果判定
        if (result.data.listW_HACCHU_SHIIRE_CSV_DATA) {
          // データを取得できた場合
          // 取得結果をリストに追加
          let resultData = result.data.listW_HACCHU_SHIIRE_CSV_DATA;
          this.items = [];
          this.initializeDate = [];
          for (const data of resultData) {
            this.items.push({
              cssSupplierCode: data.CSS_SHIIRESAKI_CD,
              cssSupplierName: data.CSS_SHIIRESAKI_NAME,
              purchaseSlipDate: data.CHAKUBI_DATE,
              realDate: data.JITSU_DATE,
              purchaseSlipNo: data.SHIIRESAKI_DENPYO_NO,
              productCode: data.SHOHIN_CD,
              productName: data.HINBAN_NO,
              amount: data.SURYO_SU,
              supplierPrice: data.SHIIRE_TANKA_GK,
              orderNo: data.CHUMON_NO,
              orderData: data.HACCHU_DATA_ID,
              orderBranchNo: data.MEISAI_NO,
              slipCheck: (data.DENPYO_KBN == 1),
              returnCheck: (data.DENPYO_KBN == 2),
              discountCheck: (data.DENPYO_KBN == 3),
              departmentCode: data.BUMON_CD,
              managerCode: data.TANTOSHA_CD,
              voucherClass: data.DENPYO_KBN,
              purchaseSlipDateFlg: false,
              realDateFlg: false,
              purchaseSlipDateMenu: false,
              realDateMenu: false,
              itemDeleteFlg: false,
            });
            this.initializeDate.push({
              purchaseSlipDate: data.CHAKUBI_DATE,
              realDate: data.JITSU_DATE,
            });
          }

          // ローディングを解除
          this.$store.commit("setLoading", false);
          // ログ出力(SQL-end_OK)
          addLog('INFO', {message: MSG.INFO["1004"], query: "listW_HACCHU_SHIIRE_CSV_DATA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          // ログ出力(method-start)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        } else {
          // データを取得できなかった場合
          // ログ出力(SQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3010"], query: "listW_HACCHU_SHIIRE_CSV_DATA", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // Exception発生の場合
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3006"], query: "listW_HACCHU_SHIIRE_CSV_DATA", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /**
     * 背景色変更処理
     * ・単価が0円のレコードが存在する場合スタイルを変更
     */
    checkSupplierPrice(item) {
      // メソッド名を定義
      const method_name="checkSupplierPrice"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      if (item.supplierPrice == 0) {
        // ログ出力(method-start)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return "style-yellow";
      } 
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 削除ボタン押下時処理
     */
    async onClickDeleteItem(item) {
      // メソッド名を定義
      const method_name="onClickDeleteItem"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      this.editedIndex = this.items.indexOf(item);

      if (confirm(CONFIRM_MESSAGE_CSV_REMOVE_DATA)) {
        this.items.splice(this.editedIndex, 1);
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 明細データ用編集カードのOK処理
     */
    save(item) {
      // メソッド名を定義
      const method_name="save"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      let targetInitDate = null;
      for (const csvItem of this.items) {
        // 仕入伝票番号とCSS仕入先コードが同じ場合
        if (item && item.purchaseSlipNo == csvItem.purchaseSlipNo && item.cssSupplierCode == csvItem.cssSupplierCode) {
          // 仕入伝票日付・実日付を直前のデータで上書き
          csvItem.purchaseSlipDate = item.purchaseSlipDate;
          csvItem.realDate = item.realDate;
          // 画面遷移時の日付から更新されている場合はBold体で表示
          targetInitDate = this.initializeDate[this.items.indexOf(csvItem)];
          csvItem.realDateFlg = targetInitDate.realDate !== csvItem.realDate
          csvItem.purchaseSlipDateFlg = targetInitDate.purchaseSlipDate !== csvItem.purchaseSlipDate
        }
      }

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 明細データ用編集カードのキャンセル押下処理
     */
    cancel() {
      // メソッド名を定義
      const method_name="cancel"
      // オペレーションタイプを定義
      const operation_type="0004"
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 設定ボタン押下処理
     * ・販売管理システムDBの営業日から取得
     * ・条件に応じて着日を上書き
     */
    async onClickSetting() {
      // メソッド名を定義
      const method_name="onClickSetting"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      // 営業日の取得
      // 検索条件
      this.todayDate = new Date();
      let sqlSearchList = ["SELECT * FROM OK_V_EIGYOUDATE WHERE ED_DATE = "];
      if(this.dateConfiguration == 0){
        // 15日締
        sqlSearchList = [sqlSearchList + (this.todayDate.getFullYear() * 10000 + (this.todayDate.getMonth() + 1) * 100 + 15),];
      } else {
        // 月末締
        this.todayDate = new Date(this.todayDate.getFullYear(), this.todayDate.getMonth(), 0);
        sqlSearchList = [sqlSearchList + (this.todayDate.getFullYear() * 10000 + (this.todayDate.getMonth() + 1) * 100 + this.todayDate.getDate()),];
      }
      if (!(await this.referenceExternalDb(sqlSearchList))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 取得した営業日をフォーマットに変更
      let beforeDate = "";
      beforeDate = await this.deleteTabStr(this.sqlSearchListResult[0][0].NEXT_DATE);
      await this.changeFormatDate(beforeDate);
      let newnNextDate = new Date(this.newDate);
      // 取得した営業日の更新
      for (const data of this.items) { 
        let realDate = new Date(data.realDate);
        if(realDate < newnNextDate ){
          data.purchaseSlipDate = this.newDate;
          data.purchaseSlipDateFlg = true;
        }
      }
      // ローディングを解除
      this.$store.commit("setLoading", false);
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 日付の変更処理
     * ・日付をフォーマット通りに変更する
     */
    async changeFormatDate(beforeDate) {
      // メソッド名を定義
      const method_name="changeFormatDate"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let y = beforeDate.substr( 0, 4 );
      let m = beforeDate.substr( 4, 2 );
      let d = beforeDate.substr( 6, 2 );
      this.newDate = y + "-" + m + "-" + d;
      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * CSV出力ボタン押下処理
     * ・CSVファイルを出力する
     * ・CSV出力フラグを立て、CSV出力日付を当日で登録する
     */
    async onClickOutPutCsv() {
      // メソッド名を定義
      const method_name="onClickOutPutCsv"
      // オペレーションタイプを定義
      const operation_type="0002"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // ローディング画面の開始
      this.$store.commit("setLoading", true);

      let result = confirm(CONFIRM_MESSAGE_CSV_INSERT_DATA);
      if( !result ) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 計上日付の取得
      let sqlSearchList = [];
      sqlSearchList = ["SELECT SY_SIKEIDT FROM KANRI WHERE SY_BASCD = " + this.loginUserInfo.BUMON_CD];
      if (!(await this.referenceExternalDb(sqlSearchList))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // 取得した営業日をフォーマットに変更
      let beforeDate = "";
      beforeDate = await this.deleteTabStr(this.sqlSearchListResult[0][0].SY_SIKEIDT);
      await this.changeFormatDate(beforeDate);

      // 請求先コードの取得
      sqlSearchList = [];
      for (const data of this.items) {
        sqlSearchList.push("SELECT SI_SEICD, SI_TORNM1, SI_TORCD FROM SHIIRE WHERE SI_TORCD = " + data.cssSupplierCode);
      }
      if (!(await this.referenceExternalDb(sqlSearchList))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      this.purchasingInfoList = this.sqlSearchListResult;

      // エラーチェック
      if (!(await this.checkError())) {
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      // 商品情報の取得
      sqlSearchList = [];
      for (const data of this.items) {
        sqlSearchList.push("SELECT SH_JAN, SH_SBUBCD, SH_SHOKNM, SH_SHORNM, SH_SHOCD FROM SHOHIN WHERE SH_SHOCD = " + data.productCode);
      }
      if (!(await this.referenceExternalDb(sqlSearchList))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      // CSVリスト生成処理
      await this.createCsvDataList()

      let csvDataStr = this.csvDataList
      if (!(await this.executeCsvFileCreation(csvDataStr))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }
      
      // 仕入CSV出力フラグと仕入CSV出力日付の登録
      sqlSearchList = [];
      for (const data of this.items) {
        if (data.itemDeleteFlg == false) {
          if (data.voucherClass !== 1) {
            sqlSearchList.push(
              " UPDATE T_HACCHU_MEISAI_DATA SET" +
                " SHIIRE_CSV_FLG = 1," +
                " SHIIRE_CSV_OUTPUT_DATE = CURDATE()," +
                " SHIIRE_AKA_FLG = 1," +
                " SHIIRE_AKA_DATE = CURDATE()," +
                " UPDATE_DATE = CURDATE()," +
                " UPDATE_USER_NAME = '" + this.loginUserInfo.TANTOSHA_NAME + "' " +
                " WHERE HACCHU_DATA_ID = " + data.orderData +
                " AND MEISAI_NO = " + data.orderBranchNo + ";",
            );
          } else {
            sqlSearchList.push(
              " UPDATE T_HACCHU_MEISAI_DATA SET" +
                " SHIIRE_CSV_FLG = 1," +
                " SHIIRE_CSV_OUTPUT_DATE = CURDATE()," +
                " UPDATE_DATE = CURDATE()," +
                " UPDATE_USER_NAME = '" + this.loginUserInfo.TANTOSHA_NAME + "' " +
                " WHERE HACCHU_DATA_ID = " + data.orderData +
                " AND MEISAI_NO = " + data.orderBranchNo + ";",
            );
          }
        }
      }
      if (!(await this.referenceInternalDb(sqlSearchList))) {
        // エラーダイアログを表示
        alert(ALERT_MESSAGE_COMMON_ERROR);
        // ローディングを解除
        this.$store.commit("setLoading", false);
        // ログ出力(method-end)
        addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
        return;
      }

      alert("仕入CSV出力が完了しました。");
      // ローディングを解除
      this.$store.commit("setLoading", false);

      // 発注検索画面に遷移
      this.$router.push("/order_search");

      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * 内部のデータベースの照会
     * ・ リスト内のSQL文をもとにデータベースから照会する
     */
    async referenceInternalDb(sqlSearchList){
      // メソッド名を定義
      const method_name="referenceInternalDb"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 検索条件
      this.sqlSearchListResult = null;
      let condition = { SQLs: sqlSearchList };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      // 実行結果取得
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeTransactSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeTransactSql.body
          );

          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeTransactSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            let message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error({ errorMessage });
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
          console.info(JSON.parse(result.data.executeTransactSql.body));
          // ログ出力(method-end)
          addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
          return true;
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeTransactSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /**
     * 他シスへのデータベースの照会
     * ・ リスト内のSQL文をもとにデータベースから照会する
     */
    async referenceExternalDb(sqlSearchList){
      // メソッド名を定義
      const method_name="referenceExternalDb"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 検索条件
      this.sqlSearchListResult = null;
      let condition = { SQLs: sqlSearchList };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→販売管理検証DB(Oracle)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeHanbaikanriSql, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          const responseBody = JSON.parse(
            result.data.executeHanbaikanriSql.body
          );
          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeHanbaikanriSql.statusCode > 200) {
            console.info({ responseBody });
            // レスポンスメッセージ
            const message = responseBody.message;
            console.error({ message });
            // エラー内容
            const errorMessage = responseBody.error;
            console.error("errorMessage = " + JSON.stringify(errorMessage));
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            this.sqlSearchListResult = [];
            this.sqlSearchListResult = responseBody.data;
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            // ログ出力(method-end)
            addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
            return true;
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ログ出力(Lamda関数でのSQL実行でエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        console.error("error" + JSON.stringify(error));
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeHanbaikanriSql", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
    },
    /**
     * エラーチェック処理
     * ・ CSV出力ボタン押下のエラーについて判別
     */
    async checkError(){
      // メソッド名を定義
      const method_name="checkError"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      // 請求先コードエラーチェック
      let i = 0; //カウントアップ用
      for (const data of this.items) {
        // エラーメッセージ
        this.todayDate = new Date();
        let alertmsg = "\n\nCSS仕入先コード：" 
          + data.cssSupplierCode + "\nCSS仕入先名　：" + data.cssSupplierName
          + "\n注文番号   　   ：" + data.orderNo + "\n明細No　　　 ：" + data.orderBranchNo
        // 「仕入単価」が0 またはNull の場合
        if (data.supplierPrice == 0 || data.supplierPrice == null) {
          let result = confirm('仕入単価が0の明細があります。このまま計上しますか？');
          if( !result ) {
            alert('最終仕入価格が設定されていません。' + alertmsg);
            return false;
          }
        }
        // 「CSS仕入先コード」が0 またはNull の場合
        if (data.cssSupplierCode == 0 || data.cssSupplierCode == null) {
          alert("CSS仕入先コードが設定されていない明細があります。" + alertmsg);
          return false;
        }
        // 「着日」がNull の場合
        if (data.purchaseSlipDate == null) {
          alert('着日が入っていない明細はCSV出力されません。'+ alertmsg);
          data.itemDeleteFlg = true;
        }
        // 「着日」が未来日付の場合
        let purchaseSlipDate = new Date(data.purchaseSlipDate);
        if (this.todayDate < purchaseSlipDate) {
          alert("着日が未来の日付では出力できません。" + alertmsg);
          return false;
        }
        // 「着日」の月が当月以外の場合
        let thisMonth = this.todayDate.getMonth() + 1
        purchaseSlipDate = purchaseSlipDate.getMonth() + 1
        if (thisMonth !== purchaseSlipDate && data.purchaseSlipDate !== null) {
          alert("月の違う着日は出力できません。" + alertmsg);
          return false;
        }
        // 「着日」が"計上日付"より前の日付の場合
        if (this.newDate > data.purchaseSlipDate) {
          alert("着日が計上日付より前の日付では出力できません。" + alertmsg);
          return false;
        }
        // 「実日付」がNull の場合
        if (data.realDate == null) {
          alert('実日付が入っていない明細はCSV出力されません。'+ alertmsg);
          data.itemDeleteFlg = true;
        }
        // 「実日付」が未来日付の場合
        let realDate = new Date(data.realDate);
        if (this.todayDate < realDate) {
          alert("実日付が未来の日付では出力できません。" + alertmsg);
          return false;
        }
        // 「実日付」が前月の15日より前の日付の場合
        let settingDate= new Date(this.todayDate.getFullYear(), this.todayDate.getMonth()-1, 15);
        if (settingDate > realDate && data.realDate !==null) {
          alert("前月の15日より前の実日付で入力できません。" + alertmsg);
          return false;
        }
        // 「請求先コード」が0 またはNull の場合
        if (this.sqlSearchListResult[i][0] == null || this.sqlSearchListResult[i][0].SI_SEICD == 0 ) {
          alert("仕入先基本テーブルに請求先コードが設定されていません。" + alertmsg);
          return false;
        }
        // 「商品コード」が0の場合
        if (data.productCode == 0) {
          alert("商品コードのない明細があります。" + alertmsg);
          return false;
        }
        // 「仕入先伝票番号」が10文字より多い場合
        if (data.purchaseSlipNo.length > 10) {
          alert("仕入先伝票番号が10文字より多い明細があります。" + alertmsg);
          return false;
        }
        // 「型番」がUnicodeで40ﾊﾞｲﾄより多い場合
        let purchaseSlipNoBytes = 0;
        // 半角英数または半角カナの場合1バイト、全角の場合2バイトとしてカウント
        for (let i = 0; i < data.productName.length; i++) {
          (data.productName[i].match(/[ -~]/)) || (data.productName[i].match(/[ｦ-ﾟ]/)) ? (purchaseSlipNoBytes += 1) : (purchaseSlipNoBytes += 2);
        }
        if ( purchaseSlipNoBytes > 40) {
          alert("型番が長過ぎます。全角で20文字まで、半角で40文字までで入力してください。" + alertmsg);
          return false;
        }
        i = i + 1;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
    /**
     * CSVリスト生成処理
     */
    async createCsvDataList() {
      // メソッド名を定義
      const method_name="createCsvDataList"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let beforeCssSupplierCode = null;
      let beforeCssSupplierName = null;
      let beforePurchaseSlipDate = null;
      let beforePurchaseSlipNo = null;
      let lineNo = null;
      let cnt = 0;
      let lineNoUpdFlg = false;
      let SlipNo = null;
      let voucher = 0

      this.csvDataList = "";
    
      for (const data of this.items) {
        if (data.itemDeleteFlg == false) {
          // 1レコード分の検索結果格納用
          let purchasingData = this.purchasingInfoList.find(
            (items) => items[0].SI_TORCD == data.cssSupplierCode
          );
          let productData = this.sqlSearchListResult.find(
            (items) => items[0].SH_SHOCD == data.productCode
          );
          // 実日付、着日8桁
          let newRealDate = data.realDate.split(`-`);
          newRealDate =  `${newRealDate[0]}${newRealDate[1]}${newRealDate[2]}`
          let newPurchaseSlipDate = data.purchaseSlipDate.split(`-`);
          newPurchaseSlipDate =  `${newPurchaseSlipDate[0]}${newPurchaseSlipDate[1]}${newPurchaseSlipDate[2]}`
          // 商品コード下5桁
          let fiveProductCode = ( '00000' + data.productCode ).slice( -5 )

          // 行番号・カウンタの生成
          if (data.cssSupplierCode !== beforeCssSupplierCode) {
            lineNoUpdFlg = true;
          } else if (data.cssSupplierName !== beforeCssSupplierName) {
            lineNoUpdFlg = true;
          } else if (data.purchaseSlipDate !== beforePurchaseSlipDate) {
            lineNoUpdFlg = true;
          } else if (data.purchaseSlipNo !== beforePurchaseSlipNo) {
            lineNoUpdFlg = true;
          } else if (lineNo == 6) {
            lineNoUpdFlg = true;
          }

          if (lineNoUpdFlg == true) {
            lineNo = 1;
            cnt = cnt + 1;
            let nowDate = new Date();
            nowDate =
              nowDate.getFullYear() +
              ( '00' + (nowDate.getMonth() + 1) ).slice( -2 ) +
              ( '00' + (nowDate.getDate()) ).slice( -2 ) +
              ( '00' + (nowDate.getHours()) ).slice( -2 ) +
              ( '00' + (nowDate.getMinutes()) ).slice( -2 ) +
              ( '00' + (nowDate.getSeconds()) ).slice( -2 );
            SlipNo = this.loginUserInfo.BUMON_CD + "3" + nowDate + ( '000' + cnt ).slice( -3 )
          } else {
            lineNo = lineNo + 1;
          }

          beforeCssSupplierCode = data.cssSupplierCode;
          beforeCssSupplierName = data.cssSupplierName;
          beforePurchaseSlipDate = data.purchaseSlipDate;
          beforePurchaseSlipNo = data.purchaseSlipNo;
          lineNoUpdFlg = false;

          // 伝票区分
          switch (data.voucherClass) {
            case 1:
              voucher = 31;
              break;
            case 2:
              voucher = 32;
              break;
            case 3:
              voucher = 33;
              break;
            default:
              break;
          }
          // JANコード
          let janCode = "";
          if (productData[0].SH_JAN !== null) {
            janCode = productData[0].SH_JAN
          }
          // 略称名
          let abbreviationName = "";
          if (productData[0].SH_SHORNM !== null) {
            abbreviationName = productData[0].SH_SHORNM
          }
          // 数量
          let amount = "";
          amount = Math.abs(data.amount);

          this.csvDataList = this.csvDataList + 
            this.loginUserInfo.BUMON_CD + "\t" + // TM_BASCD
            "3\t" + // TM_DATAKB
            data.cssSupplierCode + "\t" + // TM_TORCD
            await this.deleteTabStr(newPurchaseSlipDate) + "\t" + // TM_DENDT
            voucher + "\t" + // TM_DENKB
            SlipNo + "\t" + // TM_DENNO
            lineNo + "\t" + // TM_GYONO 
            await this.deleteTabStr(purchasingData[0].SI_SEICD) + "\t" + // TM_SEICD
            await this.deleteTabStr(purchasingData[0].SI_TORNM1) + "\t" + // TM_TORNM
            "0\t" + // TM_COKCD
            "\t" + // TM_COKNM
            "0\t" + // TM_TANCD
            "\t" + // TM_TANNM
            "0\t" + // TM_CHKCD
            this.loginUserInfo.BUMON_CD + "\t" + // TM_EBUCD
            data.productCode + "\t" + // TM_SHOCD
            data.productName + "\t" + // TM_SHONM
            await this.deleteTabStr(janCode) + "\t" + // TM_JANCD
            "0\t" + // TM_SBUACD
            await this.deleteTabStr(productData[0].SH_SBUBCD) + "\t" + // TM_SBUBCD
            "\t" + // TM_TANANO1
            "\t" + // TM_TANANO2
            "0\t" + // TM_IRISU
            amount + "\t" + // TM_SU
            "1\t" + // TM_TAICD
            "\t" + // TM_TAINM
            data.supplierPrice + "\t" + // TM_SIRTN
            "0\t" + // TM_URITN
            "0\t" + // TM_JYOTN
            Math.round(amount * data.supplierPrice) + "\t" + // TM_KN
            "0\t" + // TM_ARARI
            "0\t" + // TM_ARARITU
            "0\t" + // TM_TAXKN
            "0\t" + // TM_STANCD
            "\t" + // TM_STANNM
            "0\t" + // TM_BIKOCD
            "H-" + data.orderNo + "\t" + // TM_BIKO
            "0\t" + // TM_TEGDT
            "\t" + // TM_TEGNO
            "0\t" + // TM_BNKCD
            "\t" + // TM_BNKNM
            "0\t" + // TM_SITCD
            "\t" + // TM_SITNM
            "\t" + // TM_KOZANO
            "\t" + // TM_FURIUKE
            "0\t" + // TM_SOSAICD
            "0\t" + // TM_BINCD
            "\t" + // TM_BINNM
            "0\t" + // TM_INTANCD
            "\t" + // TM_INTANNM
            data.purchaseSlipNo + "\t" + // 仕入先伝票番号
            "0\t" + // TM_JYUHANO
            "0\t" + // TM_JYUHAGYO
            "9\t" + // TM_KARIKB
            "0\t" + // TM_KARINO
            "0\t" + // TM_KARIGYO
            "9\t" + // TM_CYOHAKB
            "0\t" + // TM_CYOHADT
            "1\t" + // TM_STAXKB
            "3\t" + // TM_TAXKB
            "1\t" + // TM_TMALKB
            "0\t" + // TM_TMLKKB
            "3\t" + // TM_MALKB
            "0\t" + // TM_MLKKB
            "9\t" + // TM_HEKTNKB
            "9\t" + // TM_MOTKB
            "9\t" + // TM_URIKAIKB
            "9\t" + // TM_SYUKB
            "9\t" + // TM_RUIKB
            "0\t" + // TM_DELKB
            "0\t" + // TM_GKN
            "0\t" + // TM_GARAKN
            "0\t" + // TM_GTAXKN
            "0\t" + // TM_TEKINMCD
            "\t" + // TM_TEKINM
            "0\t" + // TM_UNICD
            "0\t" + // TM_SHACD
            "0\t" + // TM_BUNCD
            "0\t" + // TM_HDENKB
            "\t" + // TM_NBASNM
            "\t" + // TM_MIDENM
            "\t" + // TM_URINM
            "\t" + // TM_NAINO
            "\t" + // TM_HTANNM
            "\t" + // TM_HYAKA
            "\t" + // TM_HYAKB
            "\t" + // TM_HYAKC
            "\t" + // TM_HYAKD
            "1\t" + // TM_KBN1
            "1\t" + // TM_KBN2
            "1\t" + // TM_KBN3
            "1\t" + // TM_KBN4
            "1\t" + // TM_KBN5
            "0\t" + // TM_SYORIDT
            "0\t" + // TM_OUTDT
            "0\t" + // TM_DTAIDT
            "0\t" + // TM_SEITAIDT
            "0\t" + // TM_URITAIDT
            "0\t" + // TM_ZAITAIDT
            "0\t" + // TM_DENDTO
            "0\t" + // TM_DENNOO
            "0\t" + // TM_GYONOO
            "0\t" + // TM_MEKCD
            fiveProductCode + "\t" + // TM_HOWCD
            this.loginUserInfo.BUMON_CD + "\t" + // TM_BASCD1
            "3\t" + // TM_DATAKB1
            "0\t" + // TM_TANCD1
            data.cssSupplierCode + "\t" + // TM_TORCD1
            await this.deleteTabStr(newPurchaseSlipDate) + "\t" + // TM_DENDT1
            voucher + "\t" + // TM_DENKB1
            "0\t" + // TM_DENNO1
            lineNo + "\t" + // TM_GYONO1
            this.loginUserInfo.BUMON_CD + "\t" + // TM_BASCD2
            "3\t" + // TM_DATAKB2
            "0\t" + // TM_SEITAIDT2
            await this.deleteTabStr(purchasingData[0].SI_SEICD) + "\t" + // TM_SEICD2
            data.cssSupplierCode + "\t" + // TM_TORCD2
            await this.deleteTabStr(newPurchaseSlipDate) + "\t" + // TM_DENDT2
            voucher + "\t" + // TM_DENKB2
            "0\t" + // TM_DENNO2
            lineNo + "\t" + // TM_GYONO2
            this.loginUserInfo.BUMON_CD + "\t" + // TM_BASCD3
            "3\t" + // TM_DATAKB3
            "0\t" + // TM_URITAIDT3
            await this.deleteTabStr(purchasingData[0].SI_SEICD) + "\t" + // TM_SEICD3
            data.cssSupplierCode + "\t" + // TM_TORCD3
            await this.deleteTabStr(newPurchaseSlipDate) + "\t" + // TM_DENDT3
            voucher + "\t" + // TM_DENKB3
            "0\t" + // TM_DENNO3
            lineNo + "\t" + // TM_GYONO3
            this.loginUserInfo.BUMON_CD + "\t" + // TM_BASCD4
            "0\t" + // TM_ZAITAIDT4
            data.productCode + "\t" + // TM_SHOCD4
            await this.deleteTabStr(newPurchaseSlipDate) + "\t" + // TM_DENDT4
            "1\t" + // TM_DATAKB4
            data.cssSupplierCode + "\t" + // TM_TORCD4
            voucher + "\t" + // TM_DENKB4
            "0\t" + // TM_DENNO4
            lineNo + "\t" + // TM_GYONO4
            "0\t" + // TM_SAKDT
            "0\t" + // TM_SYUDT
            await this.deleteTabStr(productData[0].SH_SHOKNM) + "\t" + // TM_SHOKNM
            "\t" + // TM_REBSBT
            await this.deleteTabStr(newRealDate) + "\t" + // TM_RDENDT
            "0\t" + // TM_NOUHINKBN
            "0\t" + // TM_PICKTIME
            "0\t" + // TM_KENPINFLG
            "0\t" + // TM_SOKOCD
            "0\t" + // TM_CYOKFLG
            "0\t" + // TM_KONPOTANI
            await this.deleteTabStr(janCode) + "\t" + // TM_JAN
            "0\t" + // TM_SHOPTN
            "\t" + // TM_NOUKITEXT
            "0\t" + // TM_NEBSITENCD
            "0\t" + // TM_NEBSEIKYU
            "0\t" + // TM_NEBDT
            "\t" + // TM_NBBIKO
            "0\t" + // TM_NBNEBADENKB
            "0\t" + // TM_HANO
            "0\t" + // TM_HAGYO
            "0\t" + // TM_HDENDT
            "0\t" + // TM_EDIDT
            "0\t" + // TM_EDINO
            "0\t" + // TM_EDIGYO
            "\t" + // TM_CHUNOH
            "\t" + // TM_KCHUNO
            data.orderNo + "\t" + // TM_CHUNOM
            "0\t" + // TM_SIMECHK
            "0\t" + // TM_SYKDT
            "0\t" + // TM_IDOBASCD
            "0\t" + // TM_IDODATAKB
            "0\t" + // TM_IDOTORCD
            "0\t" + // TM_IDODENDT
            "0\t" + // TM_IDODENKB
            "0\t" + // TM_IDONO
            "0\t" + // TM_IDOGYO
            await this.deleteTabStr(abbreviationName) + "\t" + // TM_SHORNM
            "0\t" + // TM_DPCODE
            "\t" + // TM_DPNAME
            "0\t" + // TM_TORCD5
            "\t" + // TM_TORNM5
            "0\t" + // TM_COKCD5
            "\t"// TM_COKNM5
            + "\n"
          ;
        }
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
    },
    /**
     * tab文字の削除
     */
    async deleteTabStr(csvDataStr) {
      return String(csvDataStr).replace(/\t+/g, " ");
    },
    /**
     * トランザクション関数の実行処理
     */
    async executeCsvFileCreation(csvDataStr) {
      // メソッド名を定義
      const method_name="executeCsvFileCreation"
      // オペレーションタイプを定義
      const operation_type="0004"

      // ログ出力(method-start)
      addLog('INFO', {message: MSG.INFO["1001"],}, this.$route.name, method_name, TYPE[operation_type],);
      let condition = {
        CsvKind: `shiire`,
        CsvData: csvDataStr,
        DepartmentCd: this.loginUserInfo.BUMON_CD
      };
      // ログ出力(SQL-start)
      addLog('INFO', {message: MSG.INFO["1003"],}, this.$route.name, method_name, TYPE["0003"],);
      try {
        // AppSync→AuroraServerless(MySQL)でデータ取得
        let result = await API.graphql(
          graphqlOperation(executeCsvFileCreation, condition)
        );
        // レスポンスデータを取得できた際の処理
        if (result) {
          // SQL実行でエラーが発生した場合の処理
          if (result.data.executeCsvFileCreation.statusCode > 200) {
            // ログ出力(Lamda関数でのSQL実行でステータスエラー)
            addLog('ERROR', {message: MSG.ERROR["3009"], query: "executeCsvFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
            return false;
          } else {
            // SQLが正常に終了した際の処理
            // ログ出力(SQL-end_OK)
            addLog('INFO', {message: MSG.INFO["1004"], query: "executeCsvFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          }
        } else {
          // レスポンスデータを取得できなかった際の処理
          // ログ出力(Lamda関数でのSQL実行でステータスエラー)
          addLog('ERROR', {message: MSG.ERROR["3008"], query: "executeCsvFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"],);
          return false;
        }
      } catch (error) {
        // ログ出力(exception)
        addLog('ERROR', {message: MSG.ERROR["3007"], query: "executeCsvFileCreation", where_option:condition}, this.$route.name, method_name, TYPE["0003"], error);
        return false;
      }
      // ログ出力(method-end)
      addLog('INFO', {message: MSG.INFO["1002"],}, this.$route.name, method_name, TYPE[operation_type],);
      return true;
    },
  },
};
</script>
<style scoped>
.style-yellow td {
  background-color: rgb(255, 255, 0) !important;
}
</style>